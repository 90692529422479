import { Logger, useLogger } from '../logger/hook'
import { TransientStorage, useTransientStorage } from '../transient-storage/hook'

export function useSeedbobs() {
  return useSeedbobsWith('mathseedsPrimeClient.unopenedSeedbob', useTransientStorage(), useLogger())
}

export function useSeedbobsWith(unopenedSeedbobKey: string, storage: TransientStorage, logger: Logger) {
  return [
    () => storage.getItem(unopenedSeedbobKey),
    (seedbob: string | null) => {
      if (seedbob !== null) {
        try {
          storage.setItem(unopenedSeedbobKey, seedbob)
        } catch (error) {
          // we end up here because the cache is full
          // we can swallow the error because not showing an unopened
          // seedbob is harmless
          logger.warn(`Failed to set unopened seedbob to ${seedbob}`, [error])
        }
      } else {
        storage.removeItem(unopenedSeedbobKey)
      }
    },
  ] as const
}
