import { StudentDocument } from '../../graphql/types'
import { useQuery } from '@apollo/client'
import React, { PropsWithChildren } from 'react'
import { StudentContext } from './hook'
import { useRollbarDefaults } from '../rollbar/wrapper'

export default function WithStudent({ children }: PropsWithChildren) {
  const { data, loading } = useQuery(StudentDocument)
  useRollbarDefaults({ payload: { person: { id: data?.student.id ?? null } } })
  return <StudentContext.Provider value={data?.student}>{!loading ? children : null}</StudentContext.Provider>
}
