export type {
  Activity,
  ProgressFieldsFragment as Progress,
  StudentQuery,
  GameQuery,
  FeatureFlag,
} from './generated-types'
export { ThreadId } from './generated-types'
export { ActivityType } from './generated-types'
export { LocaleId } from './generated-types'
export {
  ActivitiesDocument,
  CreateCheckupQuizResultDocument,
  CreateEndOfGradeSkillsQuizResultDocument,
  CreateEndOfGradeApplicationQuizResultDocument,
  CreateEndOfLessonQuizResultDocument,
  CreateEndOfUnitQuizResultDocument,
  CreateInteractiveActivityResultDocument,
  CreateProblemSolvingActivityResultDocument,
  CreateReviewSkillsResultDocument,
  CreateTeachingVideoResultDocument,
  CreateGameResultDocument,
  PurchaseGameDocument,
  PurchaseWoodlingDocument,
  SelectWoodlingDocument,
  WoodlingsDocument,
  StudentDocument,
  GameDocument,
  FeatureFlagType,
} from '@/graphql/generated-types'

import { Activity, ThreadId } from './generated-types'
export type LowercaseThreadId = Lowercase<ThreadId>
export type ThreadSpecifier = {
  threadId: LowercaseThreadId
}

import type { StudentQuery } from './generated-types'
export type Student = StudentQuery['student']

export function toLowercaseThreadId(threadId: ThreadId) {
  return threadId?.toLowerCase() as LowercaseThreadId
}

export function toUppercaseThreadId(threadId: LowercaseThreadId) {
  return threadId?.toUpperCase() as ThreadId
}

export function isThread(threadId: string): threadId is LowercaseThreadId {
  return Object.values(ThreadId)
    .map((id) => id.toLowerCase())
    .includes(threadId)
}

export function toContentReadableActivity(activity: Activity) {
  return {
    ...activity,
    activityType: activity.activityType?.toLowerCase()?.replace(/_/g, '-'),
  }
}
