import { createContext, useContext } from 'react'
import { ErrorContext } from '../errors/boundary'
import type { RollbarProviderProps } from './types'
import type Rollbar from 'rollbar'

export const RollbarContext = createContext<Rollbar | undefined>(undefined)

export default function RollbarProvider({ children, rollbar }: RollbarProviderProps) {
  const resolvedRollbar = rollbar ?? globalThis.Rollbar
  return (
    <RollbarContext.Provider value={resolvedRollbar}>
      <ErrorContext.Provider value={resolvedRollbar}>{children}</ErrorContext.Provider>
    </RollbarContext.Provider>
  )
}

export function useRollbarDefaults(defaults: Rollbar.Configuration) {
  useContext(RollbarContext)?.configure(defaults)
}
