import { ReadonlyURLSearchParams, useSearchParams } from 'next/navigation'
import type { StructuredConfiguration } from './types'

export function useClientOverrides(configuration: StructuredConfiguration) {
  return useClientOverridesWithParams(configuration.isBrowserBasedConfigAllowed, configuration, useSearchParams())
}

export function useClientOverridesWithParams(
  isBrowserBasedConfigAllowed: boolean,
  configuration: StructuredConfiguration,
  params: ReadonlyURLSearchParams,
) {
  if (!isBrowserBasedConfigAllowed || !params.has('api-url')) return configuration
  const apiUrl = params.get('api-url')
  return {
    ...configuration,
    graphQlUrl: `${apiUrl}/api/graphql`,
    loginUrl: `${apiUrl}/login`,
    logoutUrl: `${apiUrl}/logout`,
  }
}
