import type { ErrorMessageProps } from "../errors/types"

const doNothing = () => {}

export function ErrorMessage({ onRetry = doNothing }: ErrorMessageProps) {
  return (
    <div className="h-screen flex flex-col justify-center items-center gap-5">
      <h1 className="font-bold text-8xl">Oh no!</h1>
      <span className="text-3xl">Something went wrong.</span>
      <button
        data-testid="retry-button"
        className="rounded bg-white/50 p-4 w-64 flex justify-between items-center hover:bg-white transition-colors"
        onClick={onRetry}
      >
        <span className="text-3xl">Retry</span>
        <span className="text-4xl">&#8635;</span>
      </button>
    </div>
  )
}
