import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never }
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  /** Additional question data */
  StadiumQuestionContent: { input: any; output: any }
}

/** An activity */
export type Activity = {
  __typename: 'Activity'
  /** Name of the activity (can be null due to work-in-progress nature of this data) */
  activityName?: Maybe<Scalars['String']['output']>
  /** Determines how this activity should be treated. e.g. The endpoint used to submit results */
  activityType: ActivityType
  /** Name identifier for the curriculum, e.g. 'au' */
  curriculumId: Scalars['String']['output']
  /** Position of grade, starting at 1 (may not be related to grades in other products) */
  gradeId: Scalars['Int']['output']
  /** Name of the grade */
  gradeName: Scalars['String']['output']
  id: Scalars['ID']['output']
  /** Position of the lesson in the unit (starting at 1) */
  lessonId: Scalars['Int']['output']
  /** Name of the lesson */
  lessonName: Scalars['String']['output']
  /** Position of this activity in the lesson (starting at 1) */
  positionInLesson: Scalars['Int']['output']
  /** Name of the seedbob reward, or null if there isn't one */
  seedbob?: Maybe<Scalars['String']['output']>
  /** Identifier for the thread, e.g. fractions */
  threadId: ThreadId
  /** Position of the unit (starting at 1) */
  unitId: Scalars['Int']['output']
  /** Name of the unit */
  unitName: Scalars['String']['output']
  /** Release state */
  visibility: Visibility
}

/** Type of activity */
export enum ActivityType {
  CheckupQuiz = 'CHECKUP_QUIZ',
  EndOfGradeApplicationQuiz = 'END_OF_GRADE_APPLICATION_QUIZ',
  EndOfGradeSkillsQuiz = 'END_OF_GRADE_SKILLS_QUIZ',
  EndOfLessonQuiz = 'END_OF_LESSON_QUIZ',
  EndOfUnitQuiz = 'END_OF_UNIT_QUIZ',
  InteractiveActivity = 'INTERACTIVE_ACTIVITY',
  ProblemSolvingActivity = 'PROBLEM_SOLVING_ACTIVITY',
  Review = 'REVIEW',
  TeachingVideo = 'TEACHING_VIDEO',
}

export type CheckupQuizResult = {
  __typename: 'CheckupQuizResult'
  /** The activityId (as provided in argument) */
  activityId: Scalars['String']['output']
  /** Whether or not the student was granted a pass after sufficient attempts */
  autoPassed: Scalars['Boolean']['output']
  /** Coins the student earned by completing this activity */
  coinsEarned: Scalars['Int']['output']
  /** The number of interactions the student got correct */
  correctCount: Scalars['Int']['output']
  /** Which questions the student got right (as provided in argument) */
  correctQuestionNumbers: Array<Scalars['Int']['output']>
  /** Curriculum for this activity */
  curriculumId: CurriculumId
  id: Scalars['ID']['output']
  /** The grade that this quiz is for */
  lessonId: Scalars['Int']['output']
  /** Whether or not the student passed, if this is a scored activity */
  passed: Scalars['Boolean']['output']
  /** Position within the lesson of this activity */
  positionInLesson: Scalars['Int']['output']
  /** Thread for this activity */
  threadId: ThreadId
  /** The maximum number of interactions the student can get correct (as provided in argument) */
  totalCount: Scalars['Int']['output']
  /** Unit for this activity */
  unitId: Scalars['Int']['output']
}

/** Arguments needed to store a record of a student completing a Checkup Quiz */
export type CheckupQuizResultInput = {
  /** Unique id for the activity. e.g. au-fractions-2-2-checkup-quiz */
  activityId: Scalars['String']['input']
  /** Which questions the student got right */
  correctQuestionNumbers: Array<Scalars['Int']['input']>
  /** The maximum number of interactions the student can get correct */
  totalCount: Scalars['Int']['input']
}

/** Result of trying to add a Checkup Quiz Result */
export type CreateCheckupQuizResultOutput = {
  __typename: 'CreateCheckupQuizResultOutput'
  result: CheckupQuizResult
  /** Get the currently logged in student, or a student by id. */
  student: Student
}

/** Result of trying to add a Checkup Quiz Result */
export type CreateCheckupQuizResultOutputStudentArgs = {
  id?: InputMaybe<Scalars['Int']['input']>
  queryBy: StudentQueryBy
}

/** Result of trying to add an End of Grade Application Quiz Result */
export type CreateEndOfGradeApplicationQuizResultOutput = {
  __typename: 'CreateEndOfGradeApplicationQuizResultOutput'
  result: EndOfGradeApplicationQuizResult
  /** Get the currently logged in student, or a student by id. */
  student: Student
}

/** Result of trying to add an End of Grade Application Quiz Result */
export type CreateEndOfGradeApplicationQuizResultOutputStudentArgs = {
  id?: InputMaybe<Scalars['Int']['input']>
  queryBy: StudentQueryBy
}

/** Result of trying to add an End of Grade Skills Quiz Result */
export type CreateEndOfGradeSkillsQuizResultOutput = {
  __typename: 'CreateEndOfGradeSkillsQuizResultOutput'
  result: EndOfGradeSkillsQuizResult
  /** Get the currently logged in student, or a student by id. */
  student: Student
}

/** Result of trying to add an End of Grade Skills Quiz Result */
export type CreateEndOfGradeSkillsQuizResultOutputStudentArgs = {
  id?: InputMaybe<Scalars['Int']['input']>
  queryBy: StudentQueryBy
}

/** Result of trying to add an End of Lesson Quiz Result */
export type CreateEndOfLessonQuizResultOutput = {
  __typename: 'CreateEndOfLessonQuizResultOutput'
  result: EndOfLessonQuizResult
  /** Get the currently logged in student, or a student by id. */
  student: Student
}

/** Result of trying to add an End of Lesson Quiz Result */
export type CreateEndOfLessonQuizResultOutputStudentArgs = {
  id?: InputMaybe<Scalars['Int']['input']>
  queryBy: StudentQueryBy
}

/** Result of trying to add an End of Unit Quiz Result */
export type CreateEndOfUnitQuizResultOutput = {
  __typename: 'CreateEndOfUnitQuizResultOutput'
  result: EndOfUnitQuizResult
  /** Get the currently logged in student, or a student by id. */
  student: Student
}

/** Result of trying to add an End of Unit Quiz Result */
export type CreateEndOfUnitQuizResultOutputStudentArgs = {
  id?: InputMaybe<Scalars['Int']['input']>
  queryBy: StudentQueryBy
}

export type CreateGameResultFieldError = FieldError

export type CreateGameResultFieldErrors = {
  __typename: 'CreateGameResultFieldErrors'
  errors: Array<CreateGameResultFieldError>
}

export type CreateGameResultInput = {
  /** Game identifier (ala 'game name') */
  gameId: Scalars['String']['input']
  /** The 'level' of the game completed */
  level: Scalars['Int']['input']
}

export type CreateGameResultOutput = {
  __typename: 'CreateGameResultOutput'
  result: GameResult
  /** Get the currently logged in student, or a student by id. */
  student: Student
}

export type CreateGameResultOutputStudentArgs = {
  id?: InputMaybe<Scalars['Int']['input']>
  queryBy: StudentQueryBy
}

export type CreateGameResultPayload = CreateGameResultFieldErrors | CreateGameResultOutput

/** Result of trying to add an interactive activity result */
export type CreateInteractiveActivityResultOutput = {
  __typename: 'CreateInteractiveActivityResultOutput'
  result: InteractiveActivityResult
  /** Get the currently logged in student, or a student by id. */
  student: Student
}

/** Result of trying to add an interactive activity result */
export type CreateInteractiveActivityResultOutputStudentArgs = {
  id?: InputMaybe<Scalars['Int']['input']>
  queryBy: StudentQueryBy
}

/** Result of trying to add a problem solving activity result */
export type CreateProblemSolvingActivityResultOutput = {
  __typename: 'CreateProblemSolvingActivityResultOutput'
  result: ProblemSolvingActivityResult
  /** Get the currently logged in student, or a student by id. */
  student: Student
}

/** Result of trying to add a problem solving activity result */
export type CreateProblemSolvingActivityResultOutputStudentArgs = {
  id?: InputMaybe<Scalars['Int']['input']>
  queryBy: StudentQueryBy
}

/** Result of trying to add a review skills result */
export type CreateReviewSkillsResultOutput = {
  __typename: 'CreateReviewSkillsResultOutput'
  result: ReviewSkillsResult
  /** Get the currently logged in student, or a student by id. */
  student: Student
}

/** Result of trying to add a review skills result */
export type CreateReviewSkillsResultOutputStudentArgs = {
  id?: InputMaybe<Scalars['Int']['input']>
  queryBy: StudentQueryBy
}

/** Stadium game results to be stored */
export type CreateStadiumGameResultsInput = {
  category: StadiumGameCategory
  /** Number of questions this student answered correctly in this stadium game */
  correctCount: Scalars['Int']['input']
  gameId: Scalars['ID']['input']
  id?: InputMaybe<Scalars['ID']['input']>
  /** Stadium game difficulty level, 'easy' or 'difficult' */
  level: StadiumGameLevel
  points: Scalars['Int']['input']
  /** Final position amongst students in this game game */
  position: Scalars['Int']['input']
  /** Stadium game product name */
  product: Scalars['ID']['input']
  studentId: Scalars['ID']['input']
  /** Number of questions in total in this stadium game */
  totalCount: Scalars['Int']['input']
}

export type CreateStadiumGameResultsOutput = {
  __typename: 'CreateStadiumGameResultsOutput'
  stadiumGameResults: Array<StadiumGameResult>
}

export type CreateStadiumTicketInput = {
  scope: StadiumGameScope
}

export type CreateStadiumTicketOutput = {
  __typename: 'CreateStadiumTicketOutput'
  stadiumTicket: StadiumTicket
}

/** Result of trying to add a teaching video result */
export type CreateTeachingVideoResultOutput = {
  __typename: 'CreateTeachingVideoResultOutput'
  result: TeachingVideoResult
  /** Get the currently logged in student, or a student by id. */
  student: Student
}

/** Result of trying to add a teaching video result */
export type CreateTeachingVideoResultOutputStudentArgs = {
  id?: InputMaybe<Scalars['Int']['input']>
  queryBy: StudentQueryBy
}

/** A top-level curriculum that contains threads. */
export enum CurriculumId {
  Au = 'AU',
  Uk = 'UK',
}

export type DeleteStadiumTicketInput = {
  /** The unique id of the ticket to delete */
  id: Scalars['ID']['input']
}

export type DeleteStadiumTicketOutput = {
  __typename: 'DeleteStadiumTicketOutput'
  /** The id of the ticket deleted */
  id: Scalars['ID']['output']
}

export type EndOfGradeApplicationQuizResult = {
  __typename: 'EndOfGradeApplicationQuizResult'
  /** The activityId (as provided in argument) */
  activityId: Scalars['String']['output']
  /** Whether or not the student was granted a pass after sufficient attempts */
  autoPassed: Scalars['Boolean']['output']
  /** Coins the student earned by completing this activity */
  coinsEarned: Scalars['Int']['output']
  /** The number of questions the student got correct */
  correctCount: Scalars['Int']['output']
  /** Which questions the student got right (as provided in argument) */
  correctQuestionNumbers: Array<Scalars['Int']['output']>
  /** Curriculum for this activity */
  curriculumId: CurriculumId
  /** The grade that this quiz is for */
  gradeId: Scalars['Int']['output']
  id: Scalars['ID']['output']
  /** Whether or not the student passed the quiz */
  passed: Scalars['Boolean']['output']
  /** Thread for this activity */
  threadId: ThreadId
  /** The maximum number of questions the student can get correct (as provided in argument) */
  totalCount: Scalars['Int']['output']
  /** Unit for this activity */
  unitId: Scalars['Int']['output']
}

/** Arguments needed to store a record of a student completing an End of Grade Application Quiz */
export type EndOfGradeApplicationQuizResultInput = {
  /** Unique id for the activity. e.g. au-fractions-4-4-end-of-grade-application-quiz */
  activityId: Scalars['String']['input']
  /** Which questions the student got right */
  correctQuestionNumbers: Array<Scalars['Int']['input']>
  /** The maximum number of questions the student can get correct */
  totalCount: Scalars['Int']['input']
}

export type EndOfGradeSkillsQuizResult = {
  __typename: 'EndOfGradeSkillsQuizResult'
  /** The activityId (as provided in argument) */
  activityId: Scalars['String']['output']
  /** Whether or not the student was granted a pass after sufficient attempts */
  autoPassed: Scalars['Boolean']['output']
  /** Coins the student earned by completing this activity */
  coinsEarned: Scalars['Int']['output']
  /** The number of questions the student got correct */
  correctCount: Scalars['Int']['output']
  /** Which questions the student got right (as provided in argument) */
  correctQuestionNumbers: Array<Scalars['Int']['output']>
  /** Curriculum for this activity */
  curriculumId: CurriculumId
  /** The grade that this quiz is for */
  gradeId: Scalars['Int']['output']
  id: Scalars['ID']['output']
  /** Whether or not the student passed the quiz */
  passed: Scalars['Boolean']['output']
  /** Thread for this activity */
  threadId: ThreadId
  /** The maximum number of questions the student can get correct (as provided in argument) */
  totalCount: Scalars['Int']['output']
  /** Unit for this activity */
  unitId: Scalars['Int']['output']
}

/** Arguments needed to store a record of a student completing an End of Grade Skills Quiz */
export type EndOfGradeSkillsQuizResultInput = {
  /** Unique id for the activity. e.g. au-fractions-4-4-end-of-grade-skills-quiz */
  activityId: Scalars['String']['input']
  /** Which questions the student got right */
  correctQuestionNumbers: Array<Scalars['Int']['input']>
  /** The maximum number of questions the student can get correct */
  totalCount: Scalars['Int']['input']
}

export type EndOfLessonQuizResult = {
  __typename: 'EndOfLessonQuizResult'
  /** The activityId (as provided in argument) */
  activityId: Scalars['String']['output']
  /** Whether or not the student was granted a pass after sufficient attempts */
  autoPassed: Scalars['Boolean']['output']
  /** Coins the student earned by completing this activity */
  coinsEarned: Scalars['Int']['output']
  /** The number of interactions the student got correct */
  correctCount: Scalars['Int']['output']
  /** Which questions the student got right (as provided in argument) */
  correctQuestionNumbers: Array<Scalars['Int']['output']>
  /** Curriculum for this activity */
  curriculumId: CurriculumId
  id: Scalars['ID']['output']
  /** The lesson containing this activity */
  lessonId: Scalars['Int']['output']
  /** Whether or not the student passed, if this is a scored activity */
  passed: Scalars['Boolean']['output']
  /** The identifier of the seedbob earned from completing this quiz, or null if one was not earned */
  seedbobEarned?: Maybe<Scalars['String']['output']>
  /** Thread for this activity */
  threadId: ThreadId
  /** The maximum number of interactions the student can get correct (as provided in argument) */
  totalCount: Scalars['Int']['output']
  /** Unit for this activity */
  unitId: Scalars['Int']['output']
}

/** Arguments needed to store a record of a student completing an End of Lesson Quiz */
export type EndOfLessonQuizResultInput = {
  /** Unique id for the activity. e.g. au-fractions-2-1-end-of-lesson-quiz */
  activityId: Scalars['String']['input']
  /** Which questions the student got right */
  correctQuestionNumbers: Array<Scalars['Int']['input']>
  /** The maximum number of interactions the student can get correct */
  totalCount: Scalars['Int']['input']
}

export type EndOfUnitQuizResult = {
  __typename: 'EndOfUnitQuizResult'
  /** The activityId (as provided in argument) */
  activityId: Scalars['String']['output']
  /** Whether or not the student was granted a pass after sufficient attempts */
  autoPassed: Scalars['Boolean']['output']
  /** Coins the student earned by completing this activity */
  coinsEarned: Scalars['Int']['output']
  /** The number of interactions the student got correct */
  correctCount: Scalars['Int']['output']
  /** Which questions the student got right (as provided in argument) */
  correctQuestionNumbers: Array<Scalars['Int']['output']>
  /** Curriculum for this activity */
  curriculumId: CurriculumId
  id: Scalars['ID']['output']
  /** Whether or not the student passed, if this is a scored activity */
  passed: Scalars['Boolean']['output']
  /** Thread for this activity */
  threadId: ThreadId
  /** The maximum number of interactions the student can get correct (as provided in argument) */
  totalCount: Scalars['Int']['output']
  /** Unit for this activity */
  unitId: Scalars['Int']['output']
}

/** Arguments needed to store a record of a student completing an End of Unit Quiz */
export type EndOfUnitQuizResultInput = {
  /** Unique id for the activity. e.g. au-fractions-2-4-end-of-unit-quiz */
  activityId: Scalars['String']['input']
  /** Which questions the student got right */
  correctQuestionNumbers: Array<Scalars['Int']['input']>
  /** The maximum number of interactions the student can get correct */
  totalCount: Scalars['Int']['input']
}

export type FeatureFlag = {
  __typename: 'FeatureFlag'
  /** Name of the feature flag */
  name: Scalars['String']['output']
  /** Type of the feature flag encoded in the value */
  type: FeatureFlagType
  /** Encoded value of the feature flag, use the `type` to decode */
  value: Scalars['String']['output']
}

/** Type of the feature flag */
export enum FeatureFlagType {
  /** Boolean feature flag, value e.g. "true" */
  Boolean = 'BOOLEAN',
  /** JSON feature flag, value e.g. "{"key": "value"}" */
  Json = 'JSON',
  /** Number feature flag, value e.g. "123" */
  Number = 'NUMBER',
  /** String feature flag, value e.g. "hello-world" */
  String = 'STRING',
}

export type FieldError = {
  __typename: 'FieldError'
  code: Scalars['String']['output']
  field: Array<Scalars['String']['output']>
  message: Scalars['String']['output']
}

/** A game */
export type Game = {
  __typename: 'Game'
  /** Number of coins that will be deducted by the purchaseGame mutation */
  price: Scalars['Int']['output']
}

/** Progression-like data for games the student has played */
export type GameLevel = {
  __typename: 'GameLevel'
  /** Game identifier (ala 'game name') */
  gameId: Scalars['String']['output']
  /** Highest 'level' the student has achieved in the game */
  level: Scalars['Int']['output']
}

export type GameResult = {
  __typename: 'GameResult'
  /** Game identifier (ala 'game name') */
  gameId: Scalars['String']['output']
  /** The 'level' of the game completed */
  level: Scalars['Int']['output']
}

/** Record of a student completing an interactive activity */
export type InteractiveActivityResult = {
  __typename: 'InteractiveActivityResult'
  /** The activityId (as provided in argument) */
  activityId: Scalars['String']['output']
  /** Coins the student earned by completing this activity */
  coinsEarned: Scalars['Int']['output']
  /** Correct count (as provided in argument) */
  correctCount: Scalars['Int']['output']
  /** Curriculum for this activity */
  curriculumId: CurriculumId
  id: Scalars['ID']['output']
  /** Lesson for this activity */
  lessonId: Scalars['Int']['output']
  /** Whether or not the student passed */
  passed: Scalars['Boolean']['output']
  /** Position within the lesson of this activity */
  positionInLesson: Scalars['Int']['output']
  /** Thread for this activity */
  threadId: ThreadId
  /** Total count (as provided in argument) */
  totalCount: Scalars['Int']['output']
  /** Unit for this activity */
  unitId: Scalars['Int']['output']
}

/** Arguments needed to store a record of a student completing an interactive activity */
export type InteractiveActivityResultInput = {
  /** Unique id for the activity. e.g. au-number-4-1-interactive-activity */
  activityId: Scalars['ID']['input']
  /** Number of questions the student got right. */
  correctCount: Scalars['Int']['input']
  /** Number of questions total. */
  totalCount: Scalars['Int']['input']
}

/** A lesson */
export type Lesson = {
  __typename: 'Lesson'
  /** Name identifier for the curriculum, e.g. 'au' */
  curriculumId: Scalars['String']['output']
  /** Position of grade, starting at 1 (may not be related to grades in other products) */
  gradeId: Scalars['Int']['output']
  /** Name of the grade */
  gradeName: Scalars['String']['output']
  /** Position of the lesson in the unit (starting at 1) */
  lessonId: Scalars['Int']['output']
  /** Name of the lesson */
  lessonName: Scalars['String']['output']
  /** Identifier for the thread, e.g. fractions */
  threadId: ThreadId
  /** Position of the unit (starting at 1) */
  unitId: Scalars['Int']['output']
  /** Name of the unit */
  unitName: Scalars['String']['output']
}

/** A student locale, sourced from gravity's `accent` on a student */
export enum LocaleId {
  Au = 'AU',
  Ca = 'CA',
  Uk = 'UK',
  Us = 'US',
}

/** Record of a student completing a problem solving activity */
export type ProblemSolvingActivityResult = {
  __typename: 'ProblemSolvingActivityResult'
  /** The activityId (as provided in argument) */
  activityId: Scalars['String']['output']
  /** Coins the student earned by completing this activity */
  coinsEarned: Scalars['Int']['output']
  /** Curriculum for this activity */
  curriculumId: CurriculumId
  id: Scalars['ID']['output']
  /** Lesson for this activity */
  lessonId: Scalars['Int']['output']
  /** Whether or not the student passed */
  passed: Scalars['Boolean']['output']
  /** Position within the lesson of this activity */
  positionInLesson: Scalars['Int']['output']
  /** Thread for this activity */
  threadId: ThreadId
  /** Unit for this activity */
  unitId: Scalars['Int']['output']
}

/** Arguments needed to store a record of a student completing a Problem Solving activity */
export type ProblemSolvingActivityResultInput = {
  /** Unique id for the activity. e.g. au-fractions-4-1-problem-solving-activity */
  activityId: Scalars['ID']['input']
}

/** Progress record for a given student, precinct, curriculum, and thread */
export type Progress = {
  __typename: 'Progress'
  /** Curriculum this record belongs to */
  curriculumId: Scalars['String']['output']
  id: Scalars['ID']['output']
  /** Student is up to this lesson number in this precinct, curriculum, thread, and unit */
  lessonId: Scalars['Int']['output']
  /** Student is up to this position (video, activity, etc.) within this precinct, curriculum, thread, unit and lesson */
  positionInLesson: Scalars['Int']['output']
  /** Precinct this record belongs to, e.g. lesson */
  precinctId: Scalars['String']['output']
  /** Thread id this record belongs to, e.g. fractions */
  threadId: ThreadId
  /** Student is up to this unit number in this precinct, curriculum, and thread */
  unitId: Scalars['Int']['output']
}

export type ProgressInput = {
  /** Student is up to this lesson number in this precinct, curriculum, thread, and unit */
  lessonId: Scalars['Int']['input']
  /** Student is up to this position within this precinct, curriculum, thread, unit and lesson */
  positionInLesson: Scalars['Int']['input']
  /** Precinct this record belongs to, e.g. lesson */
  precinctId: Scalars['String']['input']
  /** Thread id this record belongs to, e.g. fractions */
  threadId: ThreadId
  /** Student is up to this unit number in this precinct, curriculum, and thread */
  unitId: Scalars['Int']['input']
}

export type PurchaseGameFieldError = FieldError

export type PurchaseGameFieldErrors = {
  __typename: 'PurchaseGameFieldErrors'
  errors: Array<PurchaseGameFieldError>
}

export type PurchaseGameInput = {
  /** Game identifier (eg. matchsticks) */
  gameId: Scalars['String']['input']
}

export type PurchaseGameOutput = {
  __typename: 'PurchaseGameOutput'
  result: PurchaseGameResult
  /** Get the currently logged in student, or a student by id. */
  student: Student
}

export type PurchaseGameOutputStudentArgs = {
  id?: InputMaybe<Scalars['Int']['input']>
  queryBy: StudentQueryBy
}

export type PurchaseGamePayload = PurchaseGameFieldErrors | PurchaseGameOutput

export type PurchaseGameResult = {
  __typename: 'PurchaseGameResult'
  /** Unique identifier of the purchase */
  id: Scalars['ID']['output']
  /** Number of coins the student was charged. */
  price: Scalars['Int']['output']
}

export type PurchaseWoodlingFieldError = FieldError

export type PurchaseWoodlingFieldErrors = {
  __typename: 'PurchaseWoodlingFieldErrors'
  errors: Array<PurchaseWoodlingFieldError>
}

export type PurchaseWoodlingInput = {
  /** Woodling identifier (e.g. 'blue_hook') */
  woodlingId: Scalars['String']['input']
}

export type PurchaseWoodlingOutput = {
  __typename: 'PurchaseWoodlingOutput'
  result: PurchaseWoodlingResult
  /** Get the currently logged in student, or a student by id. */
  student: Student
}

export type PurchaseWoodlingOutputStudentArgs = {
  id?: InputMaybe<Scalars['Int']['input']>
  queryBy: StudentQueryBy
}

export type PurchaseWoodlingPayload = PurchaseWoodlingFieldErrors | PurchaseWoodlingOutput

export type PurchaseWoodlingResult = {
  __typename: 'PurchaseWoodlingResult'
  /** Unique identifier of the purchase */
  id: Scalars['ID']['output']
  /** Number of coins the student was charged */
  price: Scalars['Int']['output']
}

/** A purchased woodling */
export type PurchasedWoodling = {
  __typename: 'PurchasedWoodling'
  /** Unique identifier of the purchase */
  id: Scalars['ID']['output']
  /** Price the student paid at purchase time */
  price: Scalars['Int']['output']
  /** Client generated UUID at the time of the purchase mutation */
  uuid: Scalars['ID']['output']
  /** Identifier of the woodling that was purchased */
  woodlingId: Scalars['ID']['output']
}

/** Record of a student completing a review skills activity */
export type ReviewSkillsResult = {
  __typename: 'ReviewSkillsResult'
  /** The activityId (as provided in argument) */
  activityId: Scalars['String']['output']
  /** Coins the student earned by completing this activity */
  coinsEarned: Scalars['Int']['output']
  /** Correct count (as provided in argument) */
  correctCount: Scalars['Int']['output']
  /** Curriculum for this activity */
  curriculumId: CurriculumId
  id: Scalars['ID']['output']
  /** Lesson for this activity */
  lessonId: Scalars['Int']['output']
  /** Whether or not the student passed */
  passed: Scalars['Boolean']['output']
  /** Position within the lesson of this activity */
  positionInLesson: Scalars['Int']['output']
  /** Thread for this activity */
  threadId: ThreadId
  /** Total count (as provided in argument) */
  totalCount: Scalars['Int']['output']
  /** Unit for this activity */
  unitId: Scalars['Int']['output']
}

/** Arguments needed to store a record of a student completing a Review Skills activity */
export type ReviewSkillsResultInput = {
  /** Unique id for the activity. e.g. au-fractions-2-1-review */
  activityId: Scalars['ID']['input']
  /** Number of questions the student got right. */
  correctCount: Scalars['Int']['input']
  /** Number of questions total. */
  totalCount: Scalars['Int']['input']
}

/** Totals of all types of student rewards */
export type RewardTotals = {
  __typename: 'RewardTotals'
  /** Total number of coins the student currently has */
  coins: Scalars['Int']['output']
  seedbobs: Array<Scalars['String']['output']>
}

export type RootMutationType = {
  __typename: 'RootMutationType'
  /** Store a record of a student completing a Checkup Quiz */
  createCheckupQuizResult: CreateCheckupQuizResultOutput
  /** Store a record of a student completing an End of Grade Application Quiz */
  createEndOfGradeApplicationQuizResult: CreateEndOfGradeApplicationQuizResultOutput
  /** Store a record of a student completing an End of Grade Skills Quiz */
  createEndOfGradeSkillsQuizResult: CreateEndOfGradeSkillsQuizResultOutput
  /** Store a record of a student completing an End of Lesson Quiz */
  createEndOfLessonQuizResult: CreateEndOfLessonQuizResultOutput
  /** Store a record of a student completing an End of Unit Quiz */
  createEndOfUnitQuizResult: CreateEndOfUnitQuizResultOutput
  /** Store a record of this game being completed at the given level */
  createGameResult: CreateGameResultPayload
  /** Store a record of a student completing an interactive activity */
  createInteractiveActivityResult: CreateInteractiveActivityResultOutput
  /** Store a record of a student completing a Problem Solving activity */
  createProblemSolvingActivityResult: CreateProblemSolvingActivityResultOutput
  /** Store a record of a student completing a Review Skills activity */
  createReviewSkillsResult: CreateReviewSkillsResultOutput
  /** Store a student's answer to a stadium game question */
  createStadiumAnswer: StadiumAnswer
  /** Store the results after completing a stadium game (requires admin role in auth token) */
  createStadiumGameResults: CreateStadiumGameResultsOutput
  /** Store a student's interest in a stadium game */
  createStadiumTicket: CreateStadiumTicketOutput
  /** Store a record of a student completing a teaching video activity */
  createTeachingVideoResult: CreateTeachingVideoResultOutput
  /** Remove a student's interest in a stadium game */
  deleteStadiumTicket: DeleteStadiumTicketOutput
  /** Deduct the purchase price of a game from the student's coins. */
  purchaseGame: PurchaseGamePayload
  /** Purchase a woodling, deduct the price from the student's coins */
  purchaseWoodling: PurchaseWoodlingPayload
  /** Select a woodling the student owns. */
  selectWoodling: SelectWoodlingPayload
  /** Set student progress */
  setProgress: SetProgressOutput
}

export type RootMutationTypeCreateCheckupQuizResultArgs = {
  input: CheckupQuizResultInput
}

export type RootMutationTypeCreateEndOfGradeApplicationQuizResultArgs = {
  input: EndOfGradeApplicationQuizResultInput
}

export type RootMutationTypeCreateEndOfGradeSkillsQuizResultArgs = {
  input: EndOfGradeSkillsQuizResultInput
}

export type RootMutationTypeCreateEndOfLessonQuizResultArgs = {
  input: EndOfLessonQuizResultInput
}

export type RootMutationTypeCreateEndOfUnitQuizResultArgs = {
  input: EndOfUnitQuizResultInput
}

export type RootMutationTypeCreateGameResultArgs = {
  input: CreateGameResultInput
}

export type RootMutationTypeCreateInteractiveActivityResultArgs = {
  input: InteractiveActivityResultInput
}

export type RootMutationTypeCreateProblemSolvingActivityResultArgs = {
  input: ProblemSolvingActivityResultInput
}

export type RootMutationTypeCreateReviewSkillsResultArgs = {
  input: ReviewSkillsResultInput
}

export type RootMutationTypeCreateStadiumAnswerArgs = {
  input: StadiumAnswerInput
}

export type RootMutationTypeCreateStadiumGameResultsArgs = {
  input: Array<CreateStadiumGameResultsInput>
}

export type RootMutationTypeCreateStadiumTicketArgs = {
  input: CreateStadiumTicketInput
}

export type RootMutationTypeCreateTeachingVideoResultArgs = {
  input: TeachingVideoResultInput
}

export type RootMutationTypeDeleteStadiumTicketArgs = {
  input: DeleteStadiumTicketInput
}

export type RootMutationTypePurchaseGameArgs = {
  input: PurchaseGameInput
}

export type RootMutationTypePurchaseWoodlingArgs = {
  input: PurchaseWoodlingInput
}

export type RootMutationTypeSelectWoodlingArgs = {
  input: SelectWoodlingInput
}

export type RootMutationTypeSetProgressArgs = {
  input: SetProgressInput
}

export type RootQueryType = {
  __typename: 'RootQueryType'
  /** List of activities */
  activities: Array<Activity>
  /** Details for a game */
  game: Game
  /** List of lessons */
  lessons: Array<Lesson>
  /** Get the stadium game by id */
  stadiumGame: StadiumGame
  /** Get the stadium leaderboard for a given student */
  stadiumLeaderboard: StadiumLeaderboard
  /** Get the stadium statistics for the current student */
  stadiumStatistics: Array<StadiumStatisticsRecord>
  /** Get the currently logged in student, or a student by id. */
  student: Student
  /** List of woodlings available for sale */
  woodlings: Array<Woodling>
}

export type RootQueryTypeActivitiesArgs = {
  curriculumId: Scalars['String']['input']
}

export type RootQueryTypeLessonsArgs = {
  curriculumId: Scalars['String']['input']
}

export type RootQueryTypeStadiumGameArgs = {
  input: StadiumGameInput
}

export type RootQueryTypeStadiumLeaderboardArgs = {
  input: StadiumLeaderboardInput
}

export type RootQueryTypeStudentArgs = {
  id?: InputMaybe<Scalars['Int']['input']>
  queryBy: StudentQueryBy
}

/** A school class */
export type SchoolClass = {
  __typename: 'SchoolClass'
  id: Scalars['ID']['output']
}

export type SelectWoodlingFieldError = FieldError

export type SelectWoodlingFieldErrors = {
  __typename: 'SelectWoodlingFieldErrors'
  errors: Array<SelectWoodlingFieldError>
}

export type SelectWoodlingInput = {
  /** Woodling identifier (e.g. 'blue_hook') */
  woodlingId: Scalars['String']['input']
}

export type SelectWoodlingOutput = {
  __typename: 'SelectWoodlingOutput'
  /** Get the currently logged in student, or a student by id. */
  student: Student
  success: Scalars['Boolean']['output']
}

export type SelectWoodlingOutputStudentArgs = {
  id?: InputMaybe<Scalars['Int']['input']>
  queryBy: StudentQueryBy
}

export type SelectWoodlingPayload = SelectWoodlingFieldErrors | SelectWoodlingOutput

export type SetProgressInput = {
  /** List of progresses to set */
  progresses: Array<ProgressInput>
  /** Student to set the progress for */
  studentId: Scalars['Int']['input']
}

export type SetProgressOutput = {
  __typename: 'SetProgressOutput'
  /** Get the currently logged in student, or a student by id. */
  student: Student
  success: Scalars['Boolean']['output']
}

export type SetProgressOutputStudentArgs = {
  id?: InputMaybe<Scalars['Int']['input']>
  queryBy: StudentQueryBy
}

export type StadiumAnswer = {
  __typename: 'StadiumAnswer'
  /** The answer text provided by the student */
  answer: Scalars['String']['output']
  /** The id of the game that the answer is for */
  gameId: Scalars['ID']['output']
  /** The id of the game question that the answer is for */
  gameQuestionId: Scalars['ID']['output']
}

export type StadiumAnswerInput = {
  /** The answer text provided by the student */
  answer: Scalars['String']['input']
  /** The id of the game that the answer is for */
  gameId: Scalars['ID']['input']
  /** The id of the game question that the answer is for */
  gameQuestionId: Scalars['ID']['input']
}

/** A stadium game record */
export type StadiumGame = {
  __typename: 'StadiumGame'
  category: StadiumGameCategory
  gameState: StadiumGameState
  id: Scalars['ID']['output']
  /** The length of the intermission between stages (in seconds). Null if the game only has one stage */
  intermissionDurationInSeconds?: Maybe<Scalars['Int']['output']>
  level: StadiumGameLevel
  players: Array<StadiumPlayer>
  scope: StadiumGameScope
  stages: Array<StadiumStage>
}

/** The category for a stadium game questions. e.g. flying_high */
export enum StadiumGameCategory {
  FlyingHigh = 'FLYING_HIGH',
  HappyTrails = 'HAPPY_TRAILS',
  SurfsUp = 'SURFS_UP',
}

export type StadiumGameInput = {
  /** Unique id for the stadium game */
  id: Scalars['ID']['input']
}

/** Difficulty level */
export enum StadiumGameLevel {
  Difficult = 'DIFFICULT',
  Easy = 'EASY',
}

/** A stadium game result record */
export type StadiumGameResult = {
  __typename: 'StadiumGameResult'
  category: StadiumGameCategory
  /** Number of coins this student earned */
  coinsEarned: Scalars['Int']['output']
  /** Number of questions this student answered correctly in this stadium game */
  correctCount: Scalars['Int']['output']
  gameId: Scalars['ID']['output']
  id?: Maybe<Scalars['ID']['output']>
  level: StadiumGameLevel
  /** Number of points won in this stadium game */
  points: Scalars['Int']['output']
  /** Final position amongst students in this game game */
  position: Scalars['Int']['output']
  /** Stadium game product name */
  product: Scalars['ID']['output']
  studentId: Scalars['ID']['output']
  /** Number of questions in total in this stadium game */
  totalCount: Scalars['Int']['output']
}

/** The scope of stadium games used in matching and leaderboard queries */
export enum StadiumGameScope {
  Class = 'CLASS',
  Practice = 'PRACTICE',
  School = 'SCHOOL',
  World = 'WORLD',
}

/** The state of the game instance */
export enum StadiumGameState {
  Abandoned = 'ABANDONED',
  Completed = 'COMPLETED',
  Created = 'CREATED',
  Started = 'STARTED',
}

/** A leaderboard */
export type StadiumLeaderboard = {
  __typename: 'StadiumLeaderboard'
  id: Scalars['ID']['output']
  /** List of top-score students, order by points, descending */
  topTotalPoints: Array<StadiumLeaderboardTopTotalPoints>
  /** The current student's total points */
  totalPoints: Scalars['Int']['output']
}

export type StadiumLeaderboardInput = {
  /** Scope to filter leaderboard results by */
  scope: StadiumGameScope
}

/** A record of a top-scoring student */
export type StadiumLeaderboardTopTotalPoints = {
  __typename: 'StadiumLeaderboardTopTotalPoints'
  /** Total points earned by this student */
  points: Scalars['Int']['output']
  /** Avatar for this student, if one is set */
  selectedAvatar?: Maybe<Scalars['String']['output']>
  /** Short name of the student */
  shortName: Scalars['String']['output']
  /** The student's unique id */
  studentId: Scalars['Int']['output']
}

/** A stadium player (participant) record */
export type StadiumPlayer = {
  __typename: 'StadiumPlayer'
  /** The avatar URL of the student */
  avatarUrl: Scalars['String']['output']
  id: Scalars['ID']['output']
  /** The short name of the student */
  shortName: Scalars['String']['output']
}

/** A question associated with a particular game instance */
export type StadiumQuestion = {
  __typename: 'StadiumQuestion'
  /** The correct answer choice for the question */
  answer: Scalars['String']['output']
  content: Scalars['StadiumQuestionContent']['output']
  id: Scalars['ID']['output']
  /** The locale that the question is written for */
  locale: Scalars['String']['output']
  /** The number of points earned for getting the question correct */
  points: Scalars['Int']['output']
}

/** A stage of a game */
export type StadiumStage = {
  __typename: 'StadiumStage'
  /** The length of the stage in seconds */
  durationInSeconds: Scalars['Int']['output']
  id: Scalars['ID']['output']
  questions: Array<StadiumQuestion>
  /** The stage's position in the sequential order of all stages for the game */
  stageNumber: Scalars['Int']['output']
}

/** Summary statistics about stadium games for a particular category */
export type StadiumStatisticsRecord = {
  __typename: 'StadiumStatisticsRecord'
  /** The student's overall accuracy rate (correct_answers / attempted_answers) rounded to a whole number */
  accuracy: Scalars['Int']['output']
  /** The name of the product category these statistics are for */
  category: StadiumGameCategory
  /** The total number of correct answer the student has given in games in this category */
  correctAnswers: Scalars['Int']['output']
  /** The number of games the student has played in this category (all time) */
  gamesPlayed: Scalars['Int']['output']
  /** The highest point score the student has achieved in a game in this category */
  highestScore: Scalars['Int']['output']
  id: Scalars['ID']['output']
  /** The total number of points the student has ever scored in games in this category */
  points: Scalars['Int']['output']
}

export type StadiumTicket = {
  __typename: 'StadiumTicket'
  /** The UUID used as a name of the ticket's Pusher channel */
  channelUuid: Scalars['String']['output']
  id: Scalars['ID']['output']
  level: StadiumGameLevel
  scope: StadiumGameScope
  /** List of class or school ids if the scope is CLASS or SCHOOL respectively */
  scopeIds?: Maybe<Array<Scalars['ID']['output']>>
  /** The identifying name of the avatar of the student who created this ticket */
  selectedAvatar: Scalars['String']['output']
  /** The short name of the student that created the ticket */
  shortName: Scalars['String']['output']
}

/** A student record */
export type Student = {
  __typename: 'Student'
  /** List of activities in the student's curriculum */
  activities: Array<Activity>
  /** The curriculum this student is in */
  curriculumId: Scalars['String']['output']
  /** Feature flags for this student */
  featureFlags: Array<FeatureFlag>
  /** First name, from accounts data */
  firstName: Scalars['String']['output']
  /** Games precinct 'progression' */
  highestGameLevels: Array<GameLevel>
  id: Scalars['ID']['output']
  /** Last name, if available, from accounts data */
  lastName?: Maybe<Scalars['String']['output']>
  /** List of lessons in the student's curriculum */
  lessons: Array<Lesson>
  /** The student's locale, for now, a copy of curriculum_id */
  localeId?: Maybe<LocaleId>
  /** Where this student is up to */
  progress: Array<Progress>
  /** List of woodlings the student has purchased */
  purchasedWoodlings: Array<PurchasedWoodling>
  /** Accumulated rewards such as coins */
  rewardTotals: RewardTotals
  /** List of school classes */
  schoolClasses: Array<SchoolClass>
  /** Currently selected woodling */
  selectedWoodling: Scalars['String']['output']
}

/** Describes how a student query will be executes */
export enum StudentQueryBy {
  /** Expect an `id` parameter and a valid JWT in the Authorization header. */
  Id = 'ID',
  /** Expect an authenicated student in the phnoenix session. */
  Session = 'SESSION',
}

/** Record of a student completing a teaching video activity */
export type TeachingVideoResult = {
  __typename: 'TeachingVideoResult'
  /** The activityId (as provided in argument) */
  activityId: Scalars['String']['output']
  /** Coins the student earned by completing this activity */
  coinsEarned: Scalars['Int']['output']
  /** Curriculum for this activity */
  curriculumId: CurriculumId
  id: Scalars['ID']['output']
  /** Lesson for this activity */
  lessonId: Scalars['Int']['output']
  /** Position within the lesson of this activity */
  positionInLesson: Scalars['Int']['output']
  /** Thread for this activity */
  threadId: ThreadId
  /** Unit for this activity */
  unitId: Scalars['Int']['output']
}

/** Arguments needed to store a record of a student completing a teaching video activity */
export type TeachingVideoResultInput = {
  /** Unique id for the activity. e.g. au-fractions-2-1-review */
  activityId: Scalars['ID']['input']
}

/** An area of learning in the product */
export enum ThreadId {
  Fractions = 'FRACTIONS',
  Geometry = 'GEOMETRY',
  Measurement = 'MEASUREMENT',
  Number = 'NUMBER',
  Operations = 'OPERATIONS',
}

/** Where the activity is in the release cycle */
export enum Visibility {
  Planned = 'PLANNED',
  PreRelease = 'PRE_RELEASE',
  ReadyForQa = 'READY_FOR_QA',
  Released = 'RELEASED',
}

/** An avatar the student can buy or select. */
export type Woodling = {
  __typename: 'Woodling'
  /** Unique identifier for the woodling, e.g. 'blue_hook'. */
  id: Scalars['String']['output']
  /** Purchase price in coins. */
  price: Scalars['Int']['output']
}

export type FeatureFlagsFragment = {
  __typename: 'Student'
  featureFlags: Array<{ __typename: 'FeatureFlag'; name: string; type: FeatureFlagType; value: string }>
}

export type ProgressFieldsFragment = {
  __typename: 'Progress'
  curriculumId: string
  lessonId: number
  positionInLesson: number
  precinctId: string
  threadId: ThreadId
  unitId: number
}

export type RewardTotalsFieldsFragment = { __typename: 'RewardTotals'; coins: number; seedbobs: Array<string> }

export type StudentProgressFieldsFragment = {
  __typename: 'Student'
  id: string
  selectedWoodling: string
  purchasedWoodlings: Array<{
    __typename: 'PurchasedWoodling'
    id: string
    price: number
    uuid: string
    woodlingId: string
  }>
  progress: Array<{
    __typename: 'Progress'
    curriculumId: string
    lessonId: number
    positionInLesson: number
    precinctId: string
    threadId: ThreadId
    unitId: number
  }>
  rewardTotals: { __typename: 'RewardTotals'; coins: number; seedbobs: Array<string> }
}

export type CreateCheckupQuizResultMutationVariables = Exact<{
  input: CheckupQuizResultInput
}>

export type CreateCheckupQuizResultMutation = {
  __typename: 'RootMutationType'
  createCheckupQuizResult: {
    __typename: 'CreateCheckupQuizResultOutput'
    result: { __typename: 'CheckupQuizResult'; passed: boolean; coinsEarned: number; autoPassed: boolean }
    student: {
      __typename: 'Student'
      id: string
      selectedWoodling: string
      purchasedWoodlings: Array<{
        __typename: 'PurchasedWoodling'
        id: string
        price: number
        uuid: string
        woodlingId: string
      }>
      progress: Array<{
        __typename: 'Progress'
        curriculumId: string
        lessonId: number
        positionInLesson: number
        precinctId: string
        threadId: ThreadId
        unitId: number
      }>
      rewardTotals: { __typename: 'RewardTotals'; coins: number; seedbobs: Array<string> }
    }
  }
}

export type CreateEndOfGradeApplicationQuizResultMutationVariables = Exact<{
  input: EndOfGradeApplicationQuizResultInput
}>

export type CreateEndOfGradeApplicationQuizResultMutation = {
  __typename: 'RootMutationType'
  createEndOfGradeApplicationQuizResult: {
    __typename: 'CreateEndOfGradeApplicationQuizResultOutput'
    result: { __typename: 'EndOfGradeApplicationQuizResult'; passed: boolean; coinsEarned: number; autoPassed: boolean }
    student: {
      __typename: 'Student'
      id: string
      selectedWoodling: string
      purchasedWoodlings: Array<{
        __typename: 'PurchasedWoodling'
        id: string
        price: number
        uuid: string
        woodlingId: string
      }>
      progress: Array<{
        __typename: 'Progress'
        curriculumId: string
        lessonId: number
        positionInLesson: number
        precinctId: string
        threadId: ThreadId
        unitId: number
      }>
      rewardTotals: { __typename: 'RewardTotals'; coins: number; seedbobs: Array<string> }
    }
  }
}

export type CreateEndOfGradeSkillsQuizResultMutationVariables = Exact<{
  input: EndOfGradeSkillsQuizResultInput
}>

export type CreateEndOfGradeSkillsQuizResultMutation = {
  __typename: 'RootMutationType'
  createEndOfGradeSkillsQuizResult: {
    __typename: 'CreateEndOfGradeSkillsQuizResultOutput'
    result: { __typename: 'EndOfGradeSkillsQuizResult'; passed: boolean; coinsEarned: number; autoPassed: boolean }
    student: {
      __typename: 'Student'
      id: string
      selectedWoodling: string
      purchasedWoodlings: Array<{
        __typename: 'PurchasedWoodling'
        id: string
        price: number
        uuid: string
        woodlingId: string
      }>
      progress: Array<{
        __typename: 'Progress'
        curriculumId: string
        lessonId: number
        positionInLesson: number
        precinctId: string
        threadId: ThreadId
        unitId: number
      }>
      rewardTotals: { __typename: 'RewardTotals'; coins: number; seedbobs: Array<string> }
    }
  }
}

export type CreateEndOfLessonQuizResultMutationVariables = Exact<{
  input: EndOfLessonQuizResultInput
}>

export type CreateEndOfLessonQuizResultMutation = {
  __typename: 'RootMutationType'
  createEndOfLessonQuizResult: {
    __typename: 'CreateEndOfLessonQuizResultOutput'
    result: {
      __typename: 'EndOfLessonQuizResult'
      passed: boolean
      coinsEarned: number
      autoPassed: boolean
      seedbobEarned?: string | null
    }
    student: {
      __typename: 'Student'
      id: string
      selectedWoodling: string
      purchasedWoodlings: Array<{
        __typename: 'PurchasedWoodling'
        id: string
        price: number
        uuid: string
        woodlingId: string
      }>
      progress: Array<{
        __typename: 'Progress'
        curriculumId: string
        lessonId: number
        positionInLesson: number
        precinctId: string
        threadId: ThreadId
        unitId: number
      }>
      rewardTotals: { __typename: 'RewardTotals'; coins: number; seedbobs: Array<string> }
    }
  }
}

export type CreateEndOfUnitQuizResultMutationVariables = Exact<{
  input: EndOfUnitQuizResultInput
}>

export type CreateEndOfUnitQuizResultMutation = {
  __typename: 'RootMutationType'
  createEndOfUnitQuizResult: {
    __typename: 'CreateEndOfUnitQuizResultOutput'
    result: { __typename: 'EndOfUnitQuizResult'; passed: boolean; coinsEarned: number; autoPassed: boolean }
    student: {
      __typename: 'Student'
      id: string
      selectedWoodling: string
      purchasedWoodlings: Array<{
        __typename: 'PurchasedWoodling'
        id: string
        price: number
        uuid: string
        woodlingId: string
      }>
      progress: Array<{
        __typename: 'Progress'
        curriculumId: string
        lessonId: number
        positionInLesson: number
        precinctId: string
        threadId: ThreadId
        unitId: number
      }>
      rewardTotals: { __typename: 'RewardTotals'; coins: number; seedbobs: Array<string> }
    }
  }
}

export type CreateGameResultMutationVariables = Exact<{
  input: CreateGameResultInput
}>

export type CreateGameResultMutation = {
  __typename: 'RootMutationType'
  createGameResult:
    | {
        __typename: 'CreateGameResultFieldErrors'
        errors: Array<{ __typename: 'FieldError'; code: string; field: Array<string>; message: string }>
      }
    | {
        __typename: 'CreateGameResultOutput'
        result: { __typename: 'GameResult'; gameId: string; level: number }
        student: {
          __typename: 'Student'
          id: string
          highestGameLevels: Array<{ __typename: 'GameLevel'; gameId: string; level: number }>
        }
      }
}

export type CreateInteractiveActivityResultMutationVariables = Exact<{
  input: InteractiveActivityResultInput
}>

export type CreateInteractiveActivityResultMutation = {
  __typename: 'RootMutationType'
  createInteractiveActivityResult: {
    __typename: 'CreateInteractiveActivityResultOutput'
    result: { __typename: 'InteractiveActivityResult'; passed: boolean; coinsEarned: number }
    student: {
      __typename: 'Student'
      id: string
      selectedWoodling: string
      purchasedWoodlings: Array<{
        __typename: 'PurchasedWoodling'
        id: string
        price: number
        uuid: string
        woodlingId: string
      }>
      progress: Array<{
        __typename: 'Progress'
        curriculumId: string
        lessonId: number
        positionInLesson: number
        precinctId: string
        threadId: ThreadId
        unitId: number
      }>
      rewardTotals: { __typename: 'RewardTotals'; coins: number; seedbobs: Array<string> }
    }
  }
}

export type CreateProblemSolvingActivityResultMutationVariables = Exact<{
  input: ProblemSolvingActivityResultInput
}>

export type CreateProblemSolvingActivityResultMutation = {
  __typename: 'RootMutationType'
  createProblemSolvingActivityResult: {
    __typename: 'CreateProblemSolvingActivityResultOutput'
    result: { __typename: 'ProblemSolvingActivityResult'; coinsEarned: number }
    student: {
      __typename: 'Student'
      id: string
      selectedWoodling: string
      purchasedWoodlings: Array<{
        __typename: 'PurchasedWoodling'
        id: string
        price: number
        uuid: string
        woodlingId: string
      }>
      progress: Array<{
        __typename: 'Progress'
        curriculumId: string
        lessonId: number
        positionInLesson: number
        precinctId: string
        threadId: ThreadId
        unitId: number
      }>
      rewardTotals: { __typename: 'RewardTotals'; coins: number; seedbobs: Array<string> }
    }
  }
}

export type CreateReviewSkillsResultMutationVariables = Exact<{
  input: ReviewSkillsResultInput
}>

export type CreateReviewSkillsResultMutation = {
  __typename: 'RootMutationType'
  createReviewSkillsResult: {
    __typename: 'CreateReviewSkillsResultOutput'
    result: { __typename: 'ReviewSkillsResult'; passed: boolean; coinsEarned: number }
    student: {
      __typename: 'Student'
      id: string
      selectedWoodling: string
      purchasedWoodlings: Array<{
        __typename: 'PurchasedWoodling'
        id: string
        price: number
        uuid: string
        woodlingId: string
      }>
      progress: Array<{
        __typename: 'Progress'
        curriculumId: string
        lessonId: number
        positionInLesson: number
        precinctId: string
        threadId: ThreadId
        unitId: number
      }>
      rewardTotals: { __typename: 'RewardTotals'; coins: number; seedbobs: Array<string> }
    }
  }
}

export type CreateTeachingVideoResultMutationVariables = Exact<{
  input: TeachingVideoResultInput
}>

export type CreateTeachingVideoResultMutation = {
  __typename: 'RootMutationType'
  createTeachingVideoResult: {
    __typename: 'CreateTeachingVideoResultOutput'
    result: { __typename: 'TeachingVideoResult'; coinsEarned: number }
    student: {
      __typename: 'Student'
      id: string
      selectedWoodling: string
      purchasedWoodlings: Array<{
        __typename: 'PurchasedWoodling'
        id: string
        price: number
        uuid: string
        woodlingId: string
      }>
      progress: Array<{
        __typename: 'Progress'
        curriculumId: string
        lessonId: number
        positionInLesson: number
        precinctId: string
        threadId: ThreadId
        unitId: number
      }>
      rewardTotals: { __typename: 'RewardTotals'; coins: number; seedbobs: Array<string> }
    }
  }
}

export type PurchaseGameMutationVariables = Exact<{
  input: PurchaseGameInput
}>

export type PurchaseGameMutation = {
  __typename: 'RootMutationType'
  purchaseGame:
    | {
        __typename: 'PurchaseGameFieldErrors'
        errors: Array<{ __typename: 'FieldError'; code: string; field: Array<string>; message: string }>
      }
    | {
        __typename: 'PurchaseGameOutput'
        result: { __typename: 'PurchaseGameResult'; price: number }
        student: { __typename: 'Student'; id: string; rewardTotals: { __typename: 'RewardTotals'; coins: number } }
      }
}

export type PurchaseWoodlingMutationVariables = Exact<{
  input: PurchaseWoodlingInput
}>

export type PurchaseWoodlingMutation = {
  __typename: 'RootMutationType'
  purchaseWoodling:
    | {
        __typename: 'PurchaseWoodlingFieldErrors'
        errors: Array<{ __typename: 'FieldError'; code: string; field: Array<string>; message: string }>
      }
    | {
        __typename: 'PurchaseWoodlingOutput'
        result: { __typename: 'PurchaseWoodlingResult'; price: number }
        student: {
          __typename: 'Student'
          id: string
          selectedWoodling: string
          purchasedWoodlings: Array<{
            __typename: 'PurchasedWoodling'
            id: string
            price: number
            uuid: string
            woodlingId: string
          }>
          progress: Array<{
            __typename: 'Progress'
            curriculumId: string
            lessonId: number
            positionInLesson: number
            precinctId: string
            threadId: ThreadId
            unitId: number
          }>
          rewardTotals: { __typename: 'RewardTotals'; coins: number; seedbobs: Array<string> }
        }
      }
}

export type SelectWoodlingMutationVariables = Exact<{
  input: SelectWoodlingInput
}>

export type SelectWoodlingMutation = {
  __typename: 'RootMutationType'
  selectWoodling:
    | {
        __typename: 'SelectWoodlingFieldErrors'
        errors: Array<{ __typename: 'FieldError'; code: string; field: Array<string>; message: string }>
      }
    | {
        __typename: 'SelectWoodlingOutput'
        student: {
          __typename: 'Student'
          id: string
          selectedWoodling: string
          purchasedWoodlings: Array<{
            __typename: 'PurchasedWoodling'
            id: string
            price: number
            uuid: string
            woodlingId: string
          }>
          progress: Array<{
            __typename: 'Progress'
            curriculumId: string
            lessonId: number
            positionInLesson: number
            precinctId: string
            threadId: ThreadId
            unitId: number
          }>
          rewardTotals: { __typename: 'RewardTotals'; coins: number; seedbobs: Array<string> }
        }
      }
}

export type ActivitiesQueryVariables = Exact<{
  curriculumId: Scalars['String']['input']
}>

export type ActivitiesQuery = {
  __typename: 'RootQueryType'
  activities: Array<{
    __typename: 'Activity'
    activityName?: string | null
    activityType: ActivityType
    curriculumId: string
    gradeId: number
    gradeName: string
    id: string
    lessonId: number
    lessonName: string
    positionInLesson: number
    threadId: ThreadId
    unitId: number
    unitName: string
    seedbob?: string | null
    visibility: Visibility
  }>
}

export type GameQueryVariables = Exact<{ [key: string]: never }>

export type GameQuery = { __typename: 'RootQueryType'; game: { __typename: 'Game'; price: number } }

export type StudentQueryVariables = Exact<{ [key: string]: never }>

export type StudentQuery = {
  __typename: 'RootQueryType'
  student: {
    __typename: 'Student'
    id: string
    localeId?: LocaleId | null
    firstName: string
    lastName?: string | null
    selectedWoodling: string
    curriculumId: string
    purchasedWoodlings: Array<{
      __typename: 'PurchasedWoodling'
      id: string
      uuid: string
      price: number
      woodlingId: string
    }>
    rewardTotals: { __typename: 'RewardTotals'; coins: number; seedbobs: Array<string> }
    progress: Array<{
      __typename: 'Progress'
      curriculumId: string
      lessonId: number
      positionInLesson: number
      precinctId: string
      threadId: ThreadId
      unitId: number
    }>
    schoolClasses: Array<{ __typename: 'SchoolClass'; id: string }>
    highestGameLevels: Array<{ __typename: 'GameLevel'; gameId: string; level: number }>
    featureFlags: Array<{ __typename: 'FeatureFlag'; name: string; type: FeatureFlagType; value: string }>
  }
}

export type WoodlingsQueryVariables = Exact<{ [key: string]: never }>

export type WoodlingsQuery = {
  __typename: 'RootQueryType'
  woodlings: Array<{ __typename: 'Woodling'; id: string; price: number }>
}

export const FeatureFlagsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeatureFlags' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Student' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'featureFlags' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FeatureFlagsFragment, unknown>
export const RewardTotalsFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RewardTotalsFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RewardTotals' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'coins' } },
          { kind: 'Field', name: { kind: 'Name', value: 'seedbobs' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RewardTotalsFieldsFragment, unknown>
export const ProgressFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProgressFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Progress' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'curriculumId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lessonId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'positionInLesson' } },
          { kind: 'Field', name: { kind: 'Name', value: 'precinctId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'threadId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unitId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProgressFieldsFragment, unknown>
export const StudentProgressFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StudentProgressFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Student' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'selectedWoodling' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'purchasedWoodlings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'woodlingId' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'progress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProgressFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rewardTotals' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'coins' } },
                { kind: 'Field', name: { kind: 'Name', value: 'seedbobs' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProgressFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Progress' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'curriculumId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lessonId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'positionInLesson' } },
          { kind: 'Field', name: { kind: 'Name', value: 'precinctId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'threadId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unitId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StudentProgressFieldsFragment, unknown>
export const CreateCheckupQuizResultDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateCheckupQuizResult' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CheckupQuizResultInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCheckupQuizResult' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'passed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'coinsEarned' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoPassed' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'student' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'queryBy' },
                      value: { kind: 'EnumValue', value: 'SESSION' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StudentProgressFields' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProgressFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Progress' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'curriculumId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lessonId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'positionInLesson' } },
          { kind: 'Field', name: { kind: 'Name', value: 'precinctId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'threadId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unitId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StudentProgressFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Student' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'selectedWoodling' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'purchasedWoodlings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'woodlingId' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'progress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProgressFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rewardTotals' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'coins' } },
                { kind: 'Field', name: { kind: 'Name', value: 'seedbobs' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateCheckupQuizResultMutation, CreateCheckupQuizResultMutationVariables>
export const CreateEndOfGradeApplicationQuizResultDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateEndOfGradeApplicationQuizResult' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'EndOfGradeApplicationQuizResultInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createEndOfGradeApplicationQuizResult' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'passed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'coinsEarned' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoPassed' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'student' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'queryBy' },
                      value: { kind: 'EnumValue', value: 'SESSION' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StudentProgressFields' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProgressFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Progress' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'curriculumId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lessonId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'positionInLesson' } },
          { kind: 'Field', name: { kind: 'Name', value: 'precinctId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'threadId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unitId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StudentProgressFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Student' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'selectedWoodling' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'purchasedWoodlings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'woodlingId' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'progress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProgressFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rewardTotals' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'coins' } },
                { kind: 'Field', name: { kind: 'Name', value: 'seedbobs' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateEndOfGradeApplicationQuizResultMutation,
  CreateEndOfGradeApplicationQuizResultMutationVariables
>
export const CreateEndOfGradeSkillsQuizResultDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateEndOfGradeSkillsQuizResult' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'EndOfGradeSkillsQuizResultInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createEndOfGradeSkillsQuizResult' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'passed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'coinsEarned' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoPassed' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'student' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'queryBy' },
                      value: { kind: 'EnumValue', value: 'SESSION' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StudentProgressFields' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProgressFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Progress' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'curriculumId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lessonId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'positionInLesson' } },
          { kind: 'Field', name: { kind: 'Name', value: 'precinctId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'threadId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unitId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StudentProgressFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Student' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'selectedWoodling' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'purchasedWoodlings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'woodlingId' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'progress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProgressFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rewardTotals' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'coins' } },
                { kind: 'Field', name: { kind: 'Name', value: 'seedbobs' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateEndOfGradeSkillsQuizResultMutation,
  CreateEndOfGradeSkillsQuizResultMutationVariables
>
export const CreateEndOfLessonQuizResultDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateEndOfLessonQuizResult' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'EndOfLessonQuizResultInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createEndOfLessonQuizResult' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'passed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'coinsEarned' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoPassed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'seedbobEarned' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'student' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'queryBy' },
                      value: { kind: 'EnumValue', value: 'SESSION' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StudentProgressFields' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProgressFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Progress' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'curriculumId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lessonId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'positionInLesson' } },
          { kind: 'Field', name: { kind: 'Name', value: 'precinctId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'threadId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unitId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StudentProgressFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Student' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'selectedWoodling' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'purchasedWoodlings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'woodlingId' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'progress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProgressFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rewardTotals' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'coins' } },
                { kind: 'Field', name: { kind: 'Name', value: 'seedbobs' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateEndOfLessonQuizResultMutation, CreateEndOfLessonQuizResultMutationVariables>
export const CreateEndOfUnitQuizResultDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateEndOfUnitQuizResult' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'EndOfUnitQuizResultInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createEndOfUnitQuizResult' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'passed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'coinsEarned' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'autoPassed' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'student' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'queryBy' },
                      value: { kind: 'EnumValue', value: 'SESSION' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StudentProgressFields' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProgressFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Progress' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'curriculumId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lessonId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'positionInLesson' } },
          { kind: 'Field', name: { kind: 'Name', value: 'precinctId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'threadId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unitId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StudentProgressFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Student' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'selectedWoodling' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'purchasedWoodlings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'woodlingId' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'progress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProgressFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rewardTotals' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'coins' } },
                { kind: 'Field', name: { kind: 'Name', value: 'seedbobs' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateEndOfUnitQuizResultMutation, CreateEndOfUnitQuizResultMutationVariables>
export const CreateGameResultDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateGameResult' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateGameResultInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createGameResult' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateGameResultFieldErrors' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'errors' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'InlineFragment',
                              typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FieldError' } },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateGameResultOutput' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'gameId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'level' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'student' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'queryBy' },
                            value: { kind: 'EnumValue', value: 'SESSION' },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'highestGameLevels' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'gameId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'level' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateGameResultMutation, CreateGameResultMutationVariables>
export const CreateInteractiveActivityResultDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateInteractiveActivityResult' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InteractiveActivityResultInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createInteractiveActivityResult' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'passed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'coinsEarned' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'student' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'queryBy' },
                      value: { kind: 'EnumValue', value: 'SESSION' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StudentProgressFields' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProgressFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Progress' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'curriculumId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lessonId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'positionInLesson' } },
          { kind: 'Field', name: { kind: 'Name', value: 'precinctId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'threadId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unitId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StudentProgressFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Student' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'selectedWoodling' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'purchasedWoodlings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'woodlingId' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'progress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProgressFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rewardTotals' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'coins' } },
                { kind: 'Field', name: { kind: 'Name', value: 'seedbobs' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateInteractiveActivityResultMutation, CreateInteractiveActivityResultMutationVariables>
export const CreateProblemSolvingActivityResultDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateProblemSolvingActivityResult' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ProblemSolvingActivityResultInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createProblemSolvingActivityResult' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coinsEarned' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'student' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'queryBy' },
                      value: { kind: 'EnumValue', value: 'SESSION' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StudentProgressFields' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProgressFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Progress' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'curriculumId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lessonId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'positionInLesson' } },
          { kind: 'Field', name: { kind: 'Name', value: 'precinctId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'threadId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unitId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StudentProgressFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Student' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'selectedWoodling' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'purchasedWoodlings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'woodlingId' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'progress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProgressFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rewardTotals' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'coins' } },
                { kind: 'Field', name: { kind: 'Name', value: 'seedbobs' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateProblemSolvingActivityResultMutation,
  CreateProblemSolvingActivityResultMutationVariables
>
export const CreateReviewSkillsResultDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateReviewSkillsResult' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ReviewSkillsResultInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createReviewSkillsResult' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'passed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'coinsEarned' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'student' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'queryBy' },
                      value: { kind: 'EnumValue', value: 'SESSION' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StudentProgressFields' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProgressFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Progress' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'curriculumId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lessonId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'positionInLesson' } },
          { kind: 'Field', name: { kind: 'Name', value: 'precinctId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'threadId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unitId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StudentProgressFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Student' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'selectedWoodling' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'purchasedWoodlings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'woodlingId' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'progress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProgressFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rewardTotals' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'coins' } },
                { kind: 'Field', name: { kind: 'Name', value: 'seedbobs' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateReviewSkillsResultMutation, CreateReviewSkillsResultMutationVariables>
export const CreateTeachingVideoResultDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateTeachingVideoResult' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'TeachingVideoResultInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createTeachingVideoResult' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coinsEarned' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'student' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'queryBy' },
                      value: { kind: 'EnumValue', value: 'SESSION' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'StudentProgressFields' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProgressFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Progress' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'curriculumId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lessonId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'positionInLesson' } },
          { kind: 'Field', name: { kind: 'Name', value: 'precinctId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'threadId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unitId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StudentProgressFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Student' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'selectedWoodling' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'purchasedWoodlings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'woodlingId' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'progress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProgressFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rewardTotals' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'coins' } },
                { kind: 'Field', name: { kind: 'Name', value: 'seedbobs' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateTeachingVideoResultMutation, CreateTeachingVideoResultMutationVariables>
export const PurchaseGameDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'PurchaseGame' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PurchaseGameInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'purchaseGame' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PurchaseGameFieldErrors' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'errors' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'InlineFragment',
                              typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FieldError' } },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PurchaseGameOutput' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'price' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'student' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'queryBy' },
                            value: { kind: 'EnumValue', value: 'SESSION' },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'rewardTotals' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'coins' } }],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PurchaseGameMutation, PurchaseGameMutationVariables>
export const PurchaseWoodlingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'PurchaseWoodling' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PurchaseWoodlingInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'purchaseWoodling' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PurchaseWoodlingFieldErrors' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'errors' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'InlineFragment',
                              typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FieldError' } },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PurchaseWoodlingOutput' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'result' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'price' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'student' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'queryBy' },
                            value: { kind: 'EnumValue', value: 'SESSION' },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StudentProgressFields' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProgressFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Progress' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'curriculumId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lessonId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'positionInLesson' } },
          { kind: 'Field', name: { kind: 'Name', value: 'precinctId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'threadId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unitId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StudentProgressFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Student' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'selectedWoodling' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'purchasedWoodlings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'woodlingId' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'progress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProgressFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rewardTotals' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'coins' } },
                { kind: 'Field', name: { kind: 'Name', value: 'seedbobs' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PurchaseWoodlingMutation, PurchaseWoodlingMutationVariables>
export const SelectWoodlingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SelectWoodling' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SelectWoodlingInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'selectWoodling' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SelectWoodlingFieldErrors' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'errors' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'InlineFragment',
                              typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FieldError' } },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SelectWoodlingOutput' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'student' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'queryBy' },
                            value: { kind: 'EnumValue', value: 'SESSION' },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'StudentProgressFields' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProgressFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Progress' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'curriculumId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lessonId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'positionInLesson' } },
          { kind: 'Field', name: { kind: 'Name', value: 'precinctId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'threadId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unitId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'StudentProgressFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Student' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'selectedWoodling' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'purchasedWoodlings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'woodlingId' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'progress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProgressFields' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rewardTotals' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'coins' } },
                { kind: 'Field', name: { kind: 'Name', value: 'seedbobs' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SelectWoodlingMutation, SelectWoodlingMutationVariables>
export const ActivitiesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Activities' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'curriculumId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activities' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'curriculumId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'curriculumId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'activityName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'activityType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'curriculumId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gradeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gradeName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lessonId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lessonName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'positionInLesson' } },
                { kind: 'Field', name: { kind: 'Name', value: 'threadId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unitId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'unitName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'seedbob' } },
                { kind: 'Field', name: { kind: 'Name', value: 'visibility' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ActivitiesQuery, ActivitiesQueryVariables>
export const GameDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Game' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'game' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'price' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GameQuery, GameQueryVariables>
export const StudentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Student' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'student' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'queryBy' },
                value: { kind: 'EnumValue', value: 'SESSION' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'localeId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'selectedWoodling' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'purchasedWoodlings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'woodlingId' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'curriculumId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rewardTotals' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'coins' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'seedbobs' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'progress' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ProgressFields' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'schoolClasses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'highestGameLevels' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'gameId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'level' } },
                    ],
                  },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FeatureFlags' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProgressFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Progress' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'curriculumId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lessonId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'positionInLesson' } },
          { kind: 'Field', name: { kind: 'Name', value: 'precinctId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'threadId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unitId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FeatureFlags' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Student' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'featureFlags' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StudentQuery, StudentQueryVariables>
export const WoodlingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Woodlings' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'woodlings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<WoodlingsQuery, WoodlingsQueryVariables>
