import { mapKeys, pick } from 'radash'
import { ClientConfigurationKey, ClientConfigurationKeys, Configuration } from './types'

export const DefaultConfiguration = process.env.client

export const forSource = (source: Record<string, string | undefined>) => ({
  readConfiguration: (namespace: string) =>
    mapKeys(
      pick(
        source,
        ClientConfigurationKeys.map((key) => `${namespace}${key}`),
      ),
      (key) => key.slice(namespace.length) as ClientConfigurationKey,
    ) as Configuration,
})

export const withBase = <T extends object>(base: T) => ({
  merge: (...sources: Partial<T>[]) =>
    [base, ...sources].reduce<T>((configuration, overrides) => Object.assign(configuration, overrides), {} as T),
})
