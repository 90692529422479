import { PropsWithChildren, createContext, useContext, useRef } from 'react'
import type { ConfigurationConsumer, StructuredConfiguration } from './types'

const ConfigurationContext = createContext<StructuredConfiguration | undefined>(undefined)

export function useConfiguration() {
  const possibleContext = useContext(ConfigurationContext)
  if (!possibleContext) throw new Error('The configuration context is not defined here.')
  return possibleContext
}

export default function WithConfiguration({ children, configuration }: PropsWithChildren<ConfigurationConsumer>) {
  const configurationRef = useRef(configuration)
  return <ConfigurationContext.Provider value={configurationRef.current}>{children}</ConfigurationContext.Provider>
}
