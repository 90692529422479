import { useRouter } from '@/parts/router/query-preserving'
import { PropsWithChildren, useEffect, useState } from 'react'
import { UiContext } from '../context'
import { useUiState } from '../hook'
import { Loader } from '../loader/component'

type RouteProperties = {
  shallow: boolean
}

export default function WithLoader({ children }: PropsWithChildren) {
  const [isUiReady, setIsUiReadyValue] = useState(false)
  const setUiToLoading = () => setIsUiReadyValue(false)
  const setUiToReady = () => setIsUiReadyValue(true)

  return (
    <UiContext.Provider value={{ setUiToLoading, setUiToReady }}>
      {!isUiReady && <Loader />}
      {children}
    </UiContext.Provider>
  )
}

export function ShowLoaderOnTransition() {
  const router = useRouter()
  const { setUiToLoading } = useUiState()

  useEffect(() => {
    function handleRouteChangeStart(_: string, { shallow }: RouteProperties) {
      if (!shallow) setUiToLoading()
    }

    router.events.on('routeChangeStart', handleRouteChangeStart)

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart)
    }
  }, [router, setUiToLoading])

  return null
}
