import { useConfiguration } from '@/parts/configuration/wrapper'
import { useFeatureFlags } from '@/parts/feature-flags/context'
import React, { Dispatch, PropsWithChildren, SetStateAction, useContext, useState } from 'react'

export type DebuggableContextValue = {
  isDebuggingAllowed: boolean
  setMockingInteractive: Dispatch<SetStateAction<boolean>>
  isMockingInteractive: boolean
  setContentDebugMode: Dispatch<SetStateAction<boolean>>
  isContentDebugMode: boolean
}

export const DebuggableContext = React.createContext<DebuggableContextValue | undefined>(undefined)

export function useDebuggableContext() {
  const possibleContext = useContext(DebuggableContext)
  if (!possibleContext) throw new Error('The debuggable context is not defined here.')
  return possibleContext
}

export default function Debuggable({ children }: PropsWithChildren) {
  const {
    debugging: { isMockingInteractiveByDefault, isAllowed: isDebuggingAllowedForEnvironment },
  } = useConfiguration()
  const {
    switches: { showDebugMode: isDebuggingAllowedForStudent = false },
  } = useFeatureFlags()
  const [isMockingInteractive, setMockingInteractive] = useState(isMockingInteractiveByDefault)
  const isDebuggingAllowed = isDebuggingAllowedForStudent || isDebuggingAllowedForEnvironment
  const [isContentDebugMode, setContentDebugMode] = useState(false)
  const contextValue = {
    isDebuggingAllowed,
    isMockingInteractive,
    setMockingInteractive,
    isContentDebugMode,
    setContentDebugMode,
  }
  return <DebuggableContext.Provider value={contextValue}>{children}</DebuggableContext.Provider>
}

export function MockInteractiveAndData({ children }: PropsWithChildren) {
  return (
    <DebuggableContext.Provider
      value={{
        isDebuggingAllowed: true,
        isMockingInteractive: true,
        setMockingInteractive: () => {},
        isContentDebugMode: true,
        setContentDebugMode: () => {},
      }}
    >
      {children}
    </DebuggableContext.Provider>
  )
}
