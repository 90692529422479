import type { NextRouter, Url } from 'next/dist/shared/lib/router/router'
import { useRouter as useNextRouter } from 'next/router'
import { pick } from 'radash'
import { UrlObject, parse } from 'url'
import { ParsedUrlQueryInput, parse as parseQuery } from 'querystring'

type TransitionOptions = Parameters<NextRouter['push']>[2]

const isStringUrl = (url: Url): url is string => typeof url === 'string'
const acceptedUrlParts: (keyof UrlObject)[] = [
  'protocol',
  'slashes',
  'auth',
  'host',
  'port',
  'hostname',
  'hash',
  'query',
  'pathname',
]

function asObject(url: Url): UrlObject {
  const urlObject = isStringUrl(url) ? parse(url) : url
  return { ...urlObject }
}

function asParsedQuery(query: string | ParsedUrlQueryInput | null | undefined): ParsedUrlQueryInput {
  if (typeof query === 'string') return parseQuery(query)
  return query ?? {}
}

export function useRouter(paramsToPreserve = ['api-url']) {
  return useQueryPreservingRouter(paramsToPreserve, useNextRouter())
}

export function useQueryPreservingRouter(paramsToPreserve: string[], router: NextRouter) {
  if (!paramsToPreserve || !paramsToPreserve.length) return router

  const { push, query } = router
  return {
    ...router,
    async push(url: Url, as?: Url, options?: TransitionOptions) {
      const targetUrl = asObject(url)
      targetUrl.query = { ...pick(query, paramsToPreserve), ...asParsedQuery(targetUrl.query) }
      const cleanedUrl = pick(targetUrl, acceptedUrlParts)
      return push(cleanedUrl, as, options)
    },
  }
}
