import { useRouter } from '@/parts/router/query-preserving'
import { useEffect } from 'react'
import { useSeedbobs } from './hook'

export function ClearSeedbobOnTransition() {
  useClearSeedbobOnTransition()
  return null
}

export function useClearSeedbobOnTransition() {
  const router = useRouter()
  const [, setSeedbob] = useSeedbobs()

  useEffect(() => {
    function handleRouteChangeStart(url: string) {
      // TODO find a better check for when to clear the unopened seedbob
      if (!url.includes('/lessons/unit')) setSeedbob(null)
    }

    router.events.on('routeChangeStart', handleRouteChangeStart)

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart)
    }
  }, [router.events, setSeedbob])
}
