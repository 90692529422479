import { memo } from 'react'
import { useDebuggableContext } from '../wrapper/component'

function InteractiveMockingToggle() {
  const context = useDebuggableContext()
  const { setMockingInteractive, isMockingInteractive, setContentDebugMode, isContentDebugMode } = context

  return (
    <>
      <button
        data-testid="interactive-mocking-button"
        className={
          'p-4 text-xs rounded text-grey-500 flex basis-1/3 grow shrink-0 text-white\
        flex-col uppercase items-center justify-center transition duration-500' +
          (isMockingInteractive ? ' bg-emerald-700' : ' bg-slate-700 opacity-10 hover:opacity-100')
        }
        onClick={() => setMockingInteractive(!isMockingInteractive)}
      >
        <span>{isMockingInteractive ? 'Mocking' : 'Real'}</span>
        <span className="font-bold text-lg">Content</span>
      </button>
      {isMockingInteractive || (
        <button
          data-testid="debug-flag-button"
          className={
            'p-4 text-xs rounded text-grey-500 flex basis-1/3 grow shrink-0 text-white\
        flex-col uppercase items-center justify-center transition duration-500' +
            (isContentDebugMode ? ' bg-emerald-700' : ' bg-slate-700 opacity-10 hover:opacity-100')
          }
          onClick={() => setContentDebugMode(!isContentDebugMode)}
        >
          <span>{isContentDebugMode ? 'ON' : 'OFF'}</span>
          <span className="font-bold text-lg">Debug</span>
        </button>
      )}
    </>
  )
}

export default memo(function DebugToggles() {
  const context = useDebuggableContext()
  const { isDebuggingAllowed } = context

  return isDebuggingAllowed ? (
    <div className="flex flex-col absolute top-4 right-4 gap-2 justify-end z-50">
      <InteractiveMockingToggle />
    </div>
  ) : null
})
