import DebugToggles from '@/parts/debug/toggles/component'
import Debuggable from '@/parts/debug/wrapper/component'
import type { AppContext, AppProps } from 'next/app'
import WithApiAccess from '@/parts/data/apollo/component'
import '@/styles/globals.css'
import WithLoader, { ShowLoaderOnTransition } from '@/parts/loading/wrapper/component'
import { ClearSeedbobOnTransition } from '@/parts/seedbob/component'
import WithStudent from '@/parts/student/wrapper'
import WithFullViewport from '@/parts/full-viewport/component'
import WithConfiguration from '@/parts/configuration/wrapper'
import type { StructuredConfiguration } from '@/parts/configuration/types'
import resolveConfiguration from '@/parts/configuration/organise'
import { useDatadogRum } from '@/parts/datadog/initialise'
import RollbarProvider from '@/parts/rollbar/wrapper'
import ErrorBoundary from '@/parts/errors/boundary'
import NextApp from 'next/app'
import WithFeatureFlags from '@/parts/feature-flags/context'
import { DeviceRotateNotification } from '@/parts/loading/rotator/component'
import { useClientOverrides } from '@/parts/configuration/client-overrides'
import Head from 'next/head'

function App({ Component, pageProps, router, ...props }: AppProps & StructuredConfiguration) {
  useDatadogRum(props)

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="user-scalable=no, width=device-width, initial-scale=1.0, maximum-scale=1.0, viewport-fit=cover"
        />
      </Head>
      <RollbarProvider>
        <ErrorBoundary>
          <WithConfiguration configuration={useClientOverrides(props)}>
            <WithLoader>
              <WithApiAccess>
                <WithStudent>
                  <WithFeatureFlags>
                    <Debuggable>
                      <WithFullViewport>
                        <DeviceRotateNotification />
                        <Component {...pageProps} />
                        <DebugToggles />
                        <ClearSeedbobOnTransition />
                        <ShowLoaderOnTransition />
                      </WithFullViewport>
                    </Debuggable>
                  </WithFeatureFlags>
                </WithStudent>
              </WithApiAccess>
            </WithLoader>
          </WithConfiguration>
        </ErrorBoundary>
      </RollbarProvider>
    </>
  )
}

App.getInitialProps = async (context: AppContext) => {
  const props = await NextApp.getInitialProps(context)
  const configuration = resolveConfiguration()
  return { ...props, ...configuration }
}

export default App
