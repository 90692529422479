import { RumInitConfiguration, datadogRum } from '@datadog/browser-rum'
import type { StructuredConfiguration } from '../configuration/types'
import { useState } from 'react'

export function useDatadogRum({
  datadog: {
    environment: env,
    version,
    rum: { applicationId, clientToken, sessionSampleRate },
  },
  graphQlUrl,
}: StructuredConfiguration) {
  const [isInitialised, setInitialised] = useState(false)

  if (process.env.NODE_ENV !== 'production' || isInitialised) return

  const configuration = {
    applicationId,
    clientToken,
    site: 'datadoghq.com',
    service: 'mathseeds-prime-client-row',
    env,
    sessionSampleRate,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: [graphQlUrl],
    version,
  } as RumInitConfiguration
  datadogRum.init(configuration)
  setInitialised(true)
}
