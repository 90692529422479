
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "CreateGameResultFieldError": [
      "FieldError"
    ],
    "CreateGameResultPayload": [
      "CreateGameResultFieldErrors",
      "CreateGameResultOutput"
    ],
    "PurchaseGameFieldError": [
      "FieldError"
    ],
    "PurchaseGamePayload": [
      "PurchaseGameFieldErrors",
      "PurchaseGameOutput"
    ],
    "PurchaseWoodlingFieldError": [
      "FieldError"
    ],
    "PurchaseWoodlingPayload": [
      "PurchaseWoodlingFieldErrors",
      "PurchaseWoodlingOutput"
    ],
    "SelectWoodlingFieldError": [
      "FieldError"
    ],
    "SelectWoodlingPayload": [
      "SelectWoodlingFieldErrors",
      "SelectWoodlingOutput"
    ]
  }
};
      export default result;
    