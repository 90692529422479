import { PropsWithChildren, createContext, useContext, useMemo } from 'react'
import { Student, FeatureFlagType } from '@/graphql/types'
import { useStudent } from '../student/hook'
import { camel, objectify } from 'radash'

export type FeatureFlags = {
  switches: Record<string, boolean>
}

export const FeatureFlagContext = createContext<FeatureFlags | undefined>(undefined)

export function useFeatureFlags() {
  const flags = useContext(FeatureFlagContext)
  if (!flags) throw new Error('No feature flag context has been defined.')
  return flags
}

type TFlag = boolean | string | number | object
type FeatureFlagContainer = Pick<Student, 'featureFlags'>

function arrangeFlagsOfType<T extends TFlag>({ featureFlags }: FeatureFlagContainer, flagType: FeatureFlagType) {
  return objectify(
    featureFlags.filter(({ type }) => type === flagType),
    ({ name }) => camel(name),
    ({ value }) => JSON.parse(value) as T,
  )
}

const extractFeatureFlags = (container: FeatureFlagContainer) => ({
  switches: arrangeFlagsOfType<boolean>(container, FeatureFlagType.Boolean),
})

export type WithFeatureFlagsProps = PropsWithChildren & {
  source?: () => FeatureFlagContainer
}

export default function WithFeatureFlags({ children, source = useStudent }: WithFeatureFlagsProps) {
  const student = source()
  const featureFlags = useMemo(() => extractFeatureFlags(student), [student])
  return <FeatureFlagContext.Provider value={featureFlags}>{children}</FeatureFlagContext.Provider>
}
