export function DeviceRotateNotification() {
  return (
    <div
      id="device-rotate-notification"
      className="bg-page fixed top-0 left-0 w-full h-screen flex-col items-center justify-center z-50 hidden touch-device:portrait:flex"
    >
      <img alt="Please rotate your device." src="/images/rotate-device-white.svg" className="w-2/3 mb-8" />
      <h2 className="text-white text-shadow text-xl sm:text-3xl text-center">Please rotate your device.</h2>
    </div>
  )
}
