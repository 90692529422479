import type { Student } from '@/graphql/types'
import { createContext, useContext } from 'react'

export const StudentContext = createContext<Student | undefined>(undefined)

export function useStudent() {
  const context = useContext(StudentContext)
  if (!context) throw new Error('No student context has been defined.')
  return context
}
