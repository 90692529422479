import { PropsWithChildren, useState, CSSProperties, useEffect, useCallback } from 'react'

export interface ViewportHeightProperties extends CSSProperties {
  '--iphone-viewport-height': string
}

function debounce(callback: () => void, delayInMilliseconds = 20) {
  let timer: ReturnType<typeof setTimeout>

  return () => {
    clearTimeout(timer)
    timer = setTimeout(() => callback(), delayInMilliseconds)
  }
}

export default function WithFullViewport({ children }: PropsWithChildren) {
  const isIPhone = /(iPhone|iPod)/.test(navigator.userAgent)

  const [height, setHeight] = useState<number>()

  const styles = { '--iphone-viewport-height': height ? `${height}px` : '' } as ViewportHeightProperties
  const classes = 'fixed inset-x-0 inset-y-0 h-iphone-viewport-height' + (isIPhone ? ' device-is-iphone' : '')

  const updateHeight = useCallback(() => {
    if (isIPhone) setHeight(window.innerHeight)
  }, [isIPhone])

  useEffect(() => {
    const handleResize = debounce(() => updateHeight())
    window.addEventListener('resize', handleResize)
    updateHeight()

    return () => window.removeEventListener('resize', handleResize)
  }, [updateHeight])

  return (
    <div style={styles} className={classes} data-testid="full-viewport">
      {children}
    </div>
  )
}
