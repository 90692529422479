import { ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client'
import introspectionResult from '@/graphql/introspection-result'
import { PropsWithChildren, useRef, useState } from 'react'
import { RetryLink } from '@apollo/client/link/retry'
import { onError } from '@apollo/client/link/error'
import { v4 as v4uuid } from 'uuid'
import { useRouter } from '@/parts/router/query-preserving'
import { useConfiguration } from '@/parts/configuration/wrapper'

export default function Apollo({ children }: PropsWithChildren) {
  const [isAuthenticated, setAuthenticated] = useState(true)
  const router = useRouter()
  const { loginUrl } = useConfiguration()

  const {
    graphQlUrl,
    retries,
    apollo: { areDevToolsConnected },
  } = useConfiguration()

  const link = ApolloLink.from([
    new ApolloLink((operation, forward) => {
      operation.setContext(({ headers = {} }) => ({
        headers: {
          ...headers,
          'X-MSP-Activity': v4uuid(),
        },
      }))

      return forward(operation)
    }),
    onError(({ graphQLErrors }) => {
      if (graphQLErrors?.find((e) => e.message.includes('Not Authenticated') || e.message.includes('Not Authorized'))) {
        setAuthenticated(false)
        router.push(loginUrl)
      }
    }),
    new RetryLink(retries),
    new HttpLink({ uri: graphQlUrl, credentials: 'include' }),
  ])
  const client = useRef(
    new ApolloClient({
      connectToDevTools: areDevToolsConnected,
      cache: new InMemoryCache({
        resultCaching: true,
        possibleTypes: introspectionResult.possibleTypes,
      }),
      link,
    }),
  ).current

  return <ApolloProvider client={client}>{isAuthenticated ? children : null}</ApolloProvider>
}
