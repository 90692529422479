import { Component, createContext, useContext } from 'react'
import type { ContextType, ErrorInfo } from 'react'
import { ErrorMessage } from '../error-message/component'
import type { ErrorBoundaryProps, ErrorBoundaryState } from './types'

export type ErrorTracker = Pick<Console, 'error'>
export const ErrorContext = createContext<ErrorTracker | undefined>(undefined)

export function useErrorTracker() {
  return useContext(ErrorContext)
}

const ok = { error: undefined }

export default class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  static override contextType = ErrorContext
  declare context: ContextType<typeof ErrorContext>
  override state = ok

  static getDerivedStateFromError(error: unknown) {
    return { error }
  }

  override componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const message = error.message
    this.context?.error(`ClientError ${message}`, errorInfo)
  }

  override render() {
    const { error } = this.state
    const { showOnError: ErrorDisplay = ErrorMessage, children } = this.props
    return error === undefined ? children : <ErrorDisplay error={error} onRetry={() => this.setState(ok)} />
  }
}
